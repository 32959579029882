import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { configJSON } from "./Settings2Controller.web";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { CloseIcon, ThreeDotIcon, EverybrandIcon, stepImageFirst } from "./assets";
import { Button, InputLabel, List, Modal, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SocialIntegrationsController, { Props } from "./SocialIntegrationsController.web";
// Customizable Area End

export default class SocialIntegrations extends SocialIntegrationsController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderModal = () => {

        return (
            <Modal
                open={!!this.state.open}
                onClose={this.closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
               
                    {
                        this.state.open === "Edit Social" ?
                            <>
                             <ModalBody>
                                <ModalTitle>
                                    {this.state.username}  integration
                                    <EditSocialCloseImgIcon src={CloseIcon} alt="" onClick={this.closeModal} />
                                </ModalTitle>
                                <form data-test-id="socialLinkFormId" onSubmit={this.onSubmit}>
                                    <FormControl>
                                        <StyledInputLabel>Social Name</StyledInputLabel>
                                        <StyledTextField data-test-id="usernameCall" variant="outlined" value={this.state.username} onChange={this.onUsernameChange} error={this.state.usernameErr ? true : false} helperText={this.state.usernameErr} />
                                    </FormControl>
                                    <FormControl>
                                        <StyledInputLabel>Link</StyledInputLabel>
                                        <StyledTextField data-test-id="socialLinkCall" variant="outlined" value={this.state.link} onChange={this.onLinkChange} error={this.state.linkErr ? true : false} helperText={this.state.linkErr} />
                                    </FormControl>
                                    <SaveBtn type="submit">save</SaveBtn>
                                    <CancelBtn data-test-id="cancelButtonCall" onClick={this.closeModal}>cancel</CancelBtn>
                                </form>
                                </ModalBody>
                            </>
                            :
                            <>
                            <ResetModalBody>
                                <ResetModalTitle>
                                    Are you sure you want to reset?
                                    <CloseImgIcon src={CloseIcon} alt="" onClick={this.closeModal} />
                                </ResetModalTitle>

                                <DeleteFooter>
                                    <DeleteBtn data-test-id="resetButtonCall" type="button" onClick={this.closeModal} >Cancel</DeleteBtn>
                                    <CancelBtn onClick={this.onSubmit}>Confirm</CancelBtn>
                                </DeleteFooter>
                            </ResetModalBody>
                        </>
                    }
                
            </Modal >
        )
    }
    renderContent = () => {
        return <>
            <Box display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1.5}
                mb={4.5}>
                <PageTitle>{configJSON.socialIntegrationsTitle}</PageTitle>
                <img style={{marginTop:"-31px"}}src={EverybrandIcon} />

            </Box>
            <SocialDisc>{configJSON.socialDescription}</SocialDisc>
            <StyledList>
                {
                    this.state.listData.map((item: any, dataindex: number) => {
                        const matchValue = this.state.defaultValuesList.find((data: any) => +item.id === data.id)

                        const showResetButton = matchValue?.name === item?.attributes?.name && matchValue?.url === item?.attributes.url


                        return (
                            <StyledListItem key={item.attributes.name} onClick={() => {this.startAuth(item.attributes.name)}} data-testId={`startAuthButton-${dataindex}`}>
                                <ListIconDiv>
                                    <ListIcon src={stepImageFirst} />
                                </ListIconDiv>
                                <StyledListItemContent>
                                    <StyledListItemHeader>
                                        <StyledListItemTitle>{item.attributes.name}</StyledListItemTitle>
                                        <StyledThreeDotIcon data-test-id="openModalCall"
                                            style={{ cursor: "pointer" }} src={ThreeDotIcon} alt=""
                                            onClick={() => this.popupHandler(item.attributes.name)}
                                        />
                                        {
                                            this.state.popupType === item.attributes.name && <StyledPopup>
                                                <PopupList>
                                                    <PopupListItem data-test-id="editSocialModalCall" onClick={() => {
                                                        this.openModal("Edit Social", item, dataindex)
                                                    }}>
                                                        <Typography
                                                            style={{
                                                                color: "#0F172A",
                                                                textTransform: "none",
                                                                fontFamily: "Inter",
                                                                fontSize: "14px",
                                                                fontStyle: "normal",
                                                                fontWeight: 400,
                                                                lineHeight: "22px"
                                                            }}
                                                        >Edit Social</Typography>
                                                    </PopupListItem>

                                                    <PopupListItem disabled={showResetButton} data-test-id="resetValues" onClick={() => {
                                                        this.openModal("Reset", matchValue, dataindex)
                                                    }}>
                                                        <Typography style={{
                                                            color: "#FE4023",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontStyle: "normal",
                                                            fontWeight: 700,
                                                            textTransform: "none",

                                                            lineHeight: "22px"
                                                        }}>Reset</Typography>
                                                    </PopupListItem>
                                                </PopupList>
                                            </StyledPopup>
                                        }
                                    </StyledListItemHeader>
                                    <StyledListItemLink>{item.attributes.url}</StyledListItemLink>
                                </StyledListItemContent>
                            </StyledListItem>
                        )
                    })
                }
            </StyledList >
        </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            
            <Box style={{ padding: "55px" }}>
                <Snackbar
                    data-testId="snakbar"
                    open={this.state.isShowAlert}
                    onClose={() => this.setState({isShowAlert: false})}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={"success"}
                    >
                        {this.state.showSucess}

                    </MuiAlert>
                </Snackbar>
                {this.renderModal()}
                {this.renderContent()}
            </Box>
        )
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

const ModalBody = styled(Box)({
    position: "relative",
    backgroundColor: "#fff",
    padding: "32px 48px 24px",
    width: "480px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "0px 0px 40px 0"
})

const ResetModalBody=styled(Box)({
 
        position: "relative",
        backgroundColor: "#fff",
        padding: "27px 26px 31px 72px",
        width: "100%",
        maxWidth: "636px",
        borderRadius: "0px 0px 20px 0",
        top: "50%",
        transform: "translate(-50%, -50%)",
        left: "50%",
    
})

const ModalTitle = styled(Typography)({
    fontSize: "20px",
    position: "relative",
    marginBottom: "38px",
    display: "flex",
})

const ResetModalTitle = styled(Typography)({
    fontSize: "20px",
    position: "relative",
    padding:"30px 0px 85px 0px",
    display: "flex",
    justifyContent: "center"
})

const FormControl = styled(Box)({
    marginBottom: "40px"
})

const StyledInputLabel = styled(InputLabel)({
    marginBottom: "8px",
    color: "#000"
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#979797",
        },
        "&:hover fieldset": {
            borderColor: "#979797",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#979797",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#ffca29",
        },
        "&.Mui-error fieldset": {
            borderColor: "red",
        },
    },
})

const SaveBtn = styled("button")({
    backgroundColor: "#ffca29",
    padding: "16px 0",
    color: "black",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
    marginBottom: "40px"

})
const CancelBtn = styled("button")({
    
    backgroundColor: "#ffca29",
    padding: "16px 0",
    color: "black",
    textTransform: "capitalize",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})

const DeleteBtn = styled("button")({
    backgroundColor: "black",
    padding: "16px 0",
    color: "white",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})

const PageTitle = styled(Typography)({
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    paddingBottom:"28px"
})


const StyledList = styled(List)({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "15px"
})

const SocialDisc = styled(Typography)({
    color: "#64748B",
    fontSize: "14px",
    fontWeigth: "400",
    lineHeight: "22px",
    marginBottom: "15px",
    fontFamily: "Inter",
    fontStyle:"normal"
})

const StyledListItem = styled(Paper)({
    width: "100%",
    padding: "16px",
    display: "flex",
    gap: "20px",
    position: "relative"
})

const ListIconDiv = styled(Box)({
    width: "44px",
    height: "45px",
    background: "#FFC629",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

const ListIcon = styled("img")({
    width: "37px",
    height: "36px",
    alignSelf: "center"
})

const StyledListItemContent = styled(Box)({
    dsplay: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "16px"
})

const StyledListItemHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})
const StyledPopup = styled(Paper)({
    position: "absolute",
    top: 0,
    right: "10px",
    backgroundColor: "#fff",
    width: "192px",
    height: "100%",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})

const StyledListItemTitle = styled(Typography)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 700
}
)

const StyledListItemLink = styled(Typography)({
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 400
})

const CloseImgIcon = styled("img")({
    position: "absolute",
    right: "-9px",
    bottom: "126px",
    cursor: "pointer",
    width: "24px",
    height: "26px",
    padding: "4px",
    borderRadius: "80px",
    justifyContent: "space-between",
    alignItems: "center",
    border:" 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "#FFF",
    boxShadow:" 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})

const EditSocialCloseImgIcon = styled("img")({
    position: "absolute",
    right: "-30px",
    bottom: "36px",
    cursor: "pointer"
})

const PopupList = styled(List)({
    display: "flex",
    flexDirection: "column",
    padding: "0 4px",
    justifyContent: "space-evenly",
    height: "100%"
})

const PopupListItem = styled(Button)({
    display: "flex",
    gap: "10px",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
    padding: "4px",
    "&:hover": {
        backgroundColor: "#FFC629",
    }
})

const DeleteFooter = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px"
})
const StyledThreeDotIcon = styled("img")({
    cursor: "pointer"
})
// Customizable Area End
